import React from "react"
import { useCustomerContext } from "@app/providers/customer"
import type { Props } from "@app/pages/account/addresses"
import type { PageProps } from "@root/types/global"
import CustomerAddressesCard from "./CustomerAddressesCard"
import CustomerLayout from "../Layout/CustomerLayout"

const CustomerAddresses: React.FC<PageProps<Props>> = ({ page }) => {
  const { customer } = useCustomerContext()

  return <CustomerLayout title={page?.title}>{customer?.id && <CustomerAddressesCard customer={customer} />}</CustomerLayout>
}

export default CustomerAddresses
