import React from "react"
import { Container, Box, VStack } from "@chakra-ui/react"
import Title from "@app/components/Sections/Title/Title"

import NavigationAccount from "@app/components/Navigation/Account/NavigationAccount"

interface Props {
  title: string | undefined
}

const CustomerLayout: React.FC<Props> = ({ title, children }) => {
  return (
    <>
      <Title title={title} />
      <Container maxW="container.md" pb={16}>
        <Box as="section" pb={4} textAlign="center"></Box>
        <VStack align="center">
          <Box p={[1, 2]}>
            <NavigationAccount />
          </Box>
          <Box p={[1, 2]} w="full">
            {children}
          </Box>
        </VStack>
      </Container>
    </>
  )
}

export default CustomerLayout
