import React from "react"
import { Box, HStack, Text } from "@chakra-ui/react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { useNavigation } from "@app/hooks/useNavigation"

const NavigationAccount: React.FC = () => {
  const { account: items } = useNavigation()
  const location = useLocation()
  return (
    <>
      {items?.length > 0 && (
        <Box as={"nav"}>
          <HStack spacing={[6, 10]}>
            {items?.map(({ title, url }) => {
              const isActive = url === location.pathname
              return (
                <Text
                  as={Link}
                  key={title}
                  to={url}
                  size={"14"}
                  color={isActive ? "grey.900" : "grey.700"}
                  textTransform="uppercase"
                  borderBottom={isActive ? "1px solid black" : "0px"}
                  fontWeight={isActive ? "semibold" : "normal"}
                  _hover={{ color: "grey.800" }}
                >
                  {title}
                </Text>
              )
            })}
          </HStack>
        </Box>
      )}
    </>
  )
}

export default React.memo(NavigationAccount)
