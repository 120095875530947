import React from "react"
import { Stack, Flex, Spinner, Button, Box } from "@chakra-ui/react"
import CustomerAddressesItem from "./CustomerAddressesItem"

const CustomerAddressesList = ({ addresses, handleAdd, handleDelete, handleEdit, handleDefault, loading }) => (
  <>
    <Box px={["0", "8"]}>
      <Button onClick={() => handleAdd()} mt={8} w={["full", "auto"]}>
        Add New Address
      </Button>
    </Box>
    {addresses?.length ? (
      <Stack direction={"column"} width="full" spacing="4">
        {addresses?.map((address: any) => (
          <CustomerAddressesItem
            key={address?.id?.toString()}
            address={address}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleDefault={handleDefault}
            loading={loading}
          />
        ))}
      </Stack>
    ) : loading ? (
      <Flex align="center" justify="center" w="full" p="4">
        <Spinner />
      </Flex>
    ) : null}
  </>
)

export default React.memo(CustomerAddressesList)
