import React from "react"
import { useCustomerAddress } from "@app/hooks/useCustomer"
import CustomerAddressesList from "./CustomerAddressesList"
import CustomerAddressesForm from "./CustomerAddressesForm"

const CustomerAddressesCard: React.FC = () => {
  const {
    addresses,
    address,
    errors,
    fetched,
    handleAdd,
    handleCancel,
    handleChange,
    handleDefault,
    handleDelete,
    handleEdit,
    handleSubmit,
    loading,
    saving,
  } = useCustomerAddress()

  return (
    <>
      {address?.action?.length ? (
        <CustomerAddressesForm
          address={address}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          loading={loading}
          errors={errors}
        />
      ) : (
        <CustomerAddressesList
          addresses={addresses}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleAdd={handleAdd}
          handleDefault={handleDefault}
          loading={!fetched || loading || saving}
        />
      )}
    </>
  )
}

export default React.memo(CustomerAddressesCard)
