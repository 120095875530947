import React from "react"
import { HStack, Box, Text, Button, VStack, Tag } from "@chakra-ui/react"
import { BiEdit, BiX } from "react-icons/bi"

const CustomerAddressesItem = ({ address, handleDelete, handleEdit, handleDefault, loading }) => {
  return (
    <Box key={address?.id} py={["2", "4"]} px={["0", "8"]} bg="brand.light">
      <HStack justify="space-between" mb={2}>
        <Text fontWeight="600" textTransform="uppercase">
          {address?.firstName} {address?.lastName}
        </Text>
        <HStack>
          {address?.default ? (
            <Tag bg="black" color="white" rounded="0">
              Default
            </Tag>
          ) : null}
          <Button
            onClick={() => handleEdit(address?.id)}
            disabled={loading}
            variant="ghost"
            textTransform="none"
            size="sm"
            fontWeight="normal"
            letterSpacing="0"
            fontSize="14px"
            px="2"
          >
            <BiEdit />
            &nbsp;Edit
          </Button>
          <Button
            onClick={() => handleDelete(address?.id)}
            disabled={loading}
            variant="ghost"
            textTransform="none"
            size="sm"
            fontWeight="normal"
            letterSpacing="0"
            fontSize="14px"
            px="2"
          >
            <BiX />
            &nbsp;Delete
          </Button>
        </HStack>
      </HStack>
      <VStack align="start" bg={"grey.100"} p={4}>
        <Box width="full">
          {address?.phone && <Text size="14">{`Phone: ${address?.phone}`}</Text>}
          <Text size="14">{`Address: ${address?.address1}, ${address?.city}, ${address?.province},
              ${address?.zip}`}</Text>
          <Text size="14">{`Country: ${address?.country}`}</Text>
        </Box>
        {!address?.default && (
          <Button
            variant="ghost"
            textTransform="none"
            size="sm"
            fontWeight="normal"
            letterSpacing="0"
            fontSize="14px"
            px="0"
            textDecoration="underline"
            onClick={() => handleDefault(address?.id)}
            disabled={loading}
          >
            Set as Default
          </Button>
        )}
      </VStack>
    </Box>
  )
}

export default React.memo(CustomerAddressesItem)
